import React from 'react'
import { Helmet } from 'react-helmet'
import { parse as ParseQuery } from 'query-string'
import logo from '../images/3dmeetLogo.png'

import GoogleAuth from '../components/GoogleClassroom/GoogleAuth'
import AttendSession from '../components/GoogleClassroom/AttendSession'
import LoadableGAPIProvider from '../components/GoogleClassroom/LoadableGAPIProvider'
import '../styles/global.css'

const LOGO_CLASSES = 'logo'
const LOGO_ALT = '3Dmeet.com'

const pageStyles = {
  color: '#ffffff',
  padding: 96,
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
}

const ATTEND_SCOPES = [
  'https://www.googleapis.com/auth/classroom.courses.readonly',
  'https://www.googleapis.com/auth/classroom.rosters.readonly',
]

export interface LocationInfo {
  hash: string
  host: string
  hostname: string
  href: string
  key: string
  origin: string
  pathname: string
  port: string
  protocol: string
  search: string
  state: unknown
}

export interface JoinPageProps {
  location: LocationInfo
  [key: string]: unknown
}

const JoinPage = ({ location }: JoinPageProps): JSX.Element => {
  const { class: classId } = ParseQuery(location.search)
  return (
    <LoadableGAPIProvider clientId={process.env.GATSBY_GAPI_CLIENT_ID}>
      <main style={pageStyles}>
        <Helmet>
          <title>Join 3Dmeet Session</title>
        </Helmet>

        <img src={logo} className={LOGO_CLASSES} alt={LOGO_ALT} />
        <GoogleAuth requestedScopes={ATTEND_SCOPES} />
        <AttendSession classId={classId as string} />
      </main>
    </LoadableGAPIProvider>
  )
}

export default JoinPage
