import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import '../../styles/global.css'

import { GoogleClass, GoogleClassState } from '../../types/GoogleClassroom'

const MAIN_TEXT_CLASSES = 'center-text main-text-message'

// HACK: This prevents the typescript error of `Property 'gapi' does not exist on type 'Window & typeof globalThis'` and we can't import it
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
declare const window: Window & typeof globalThis & { gapi: any }

export interface AttendSessionProps {
  classId: string
}

export interface TokenResponse {
  redirect: string
  error?: string
}

const AttendSession = ({ classId }: AttendSessionProps): JSX.Element => {
  const [gapi, setGAPI] = useState(undefined)
  const [course, setCourse] = useState<GoogleClass>(undefined)
  const [requestingToken, setRequestingToken] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const retrieveGAPI = useCallback(() => {
    if (typeof gapi === 'undefined' && typeof window !== 'undefined') {
      setGAPI(window.gapi)
      setTimeout(retrieveGAPI, 0)
    }
  }, [gapi, setGAPI])

  useEffect(() => {
    setTimeout(retrieveGAPI, 0)
  }, [])

  const get3DmeetToken = async (courseId: string) => {
    try {
      const user = gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .getAuthResponse()
      const tokenResponse = await axios.get(
        process.env.GATSBY_3DMEET_TOKEN_URL,
        {
          headers: {
            authorization: `${user.token_type} ${user.access_token}`,
          },
          params: {
            source: 'gc',
            courseId,
          },
        },
      )
      const result: TokenResponse = tokenResponse.data
      const { redirect } = result

      if (redirect) {
        window.location.replace(redirect)
      }
    } catch (err) {
      console.error('Error occured while fetching the token:', err)
      const { error } = err.response.data
      setErrorMessage(error ?? 'Unknown Error')
    }
    setRequestingToken(false)
  }

  const getCourseInfo = useCallback(async () => {
    if (
      !gapi ||
      !gapi.client ||
      !gapi.client.classroom ||
      !gapi?.auth2?.getAuthInstance().isSignedIn.get()
    ) {
      setTimeout(getCourseInfo, 0)
      return
    }

    try {
      const courseResult = await gapi.client.classroom.courses.get({
        id: classId,
        courseStates: [GoogleClassState.Active],
      })

      setCourse(courseResult.result)
      setRequestingToken(true)
      get3DmeetToken(classId)
    } catch (err) {
      console.error('Could not retrieve course info', err)
    }
  }, [gapi, setCourse])

  useEffect(() => {
    getCourseInfo()
  }, [gapi])

  return !classId ? (
    <div className={MAIN_TEXT_CLASSES}>
      Class Not Found!
      <br />
      Please check the link and try again.
    </div>
  ) : requestingToken ? (
    <div className={MAIN_TEXT_CLASSES}>Retrieving token for {course.name}</div>
  ) : !course ? (
    <div className={MAIN_TEXT_CLASSES}>No course found</div>
  ) : errorMessage ? (
    <div className={MAIN_TEXT_CLASSES}>{errorMessage}</div>
  ) : (
    <div className={MAIN_TEXT_CLASSES}>Token received, redirecting now</div>
  )
}

export default AttendSession
